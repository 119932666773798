<template>
  <div id="Paiement" class="w-100 d-flex flex-row justify-content-center align-items-start overflow-visible">
    <div class="p-3 px-5 pb-md-5 flex-md-grow-1 h-fit">

      <div class="fs-5 text-center mt-3 mb-4">
        {{ $tcust("Paiement.title") }}
      </div>

      <BigLicenseSwitcher class="mx-auto mb-4" />

      <MDBCard
        v-if="$store.state.licenseChosen.License.Type === 0"
        class="gradient custom-box mx-auto my-3"
      >
        <MDBContainer
          class="p-0"
          fluid
        >
          <MDBRow>
            <!-- Title -->
            <MDBCol
              col="12"
              class="inside-block"
            >
              <div class="bg-box d-flex flex-row flex-wrap justify-content-between align-items-center px-3 py-2">
                <MDBBadge
                  color="primary"
                  tag="div"
                  class="h-fit"
                >
                  <div class="fst-italic fw-normal w-fit fs-5">
                    eecheck One <span class="custom-fold-size">{{ $tcust("LicensesList.personal.badge") }}</span>
                  </div>
                </MDBBadge>
                <div class="mx-3 my-1" />
                <div class="mx-2 mt-2">
                  <span class="fst-italic me-2">{{ $tcust("LicensesList.owner") }}</span>
                  <span class="fw-bold">{{ $store.state.user.Person.FullName }}</span>
                </div>

                <div
                  v-if="$store.state.user.OneLicensePlan.AvailableCreditsCount === 2147483647"
                  class="fst-italic text-muted"
                >
                  {{ $tcust("license.validityDate", { date: $store.state.user.OneLicensePlan.ValidityDate }) }}
                </div>
              </div>
            </MDBCol>

            <!-- Purchases -->
            <MDBCol
              col="12"
              class="inside-block"
            >
              <div
                v-if="$store.state.user.OneLicensePlan.AvailableCreditsCount !== 2147483647"
                class="bg-box p-3 w-100"
              >
                <div class="d-flex flex-row flex-wrap justify-content-start align-items-center mx-auto w-100">
                  <div class="fw-bold w-fit">
                    {{ $tcust("LicensesList.credits", { n: $store.state.user.OneLicensePlan.AvailableCreditsCount }) }}
                  </div>

                  <div class="w-fit d-flex flex-row flex-wrap my-3 mx-auto">
                    <div class="d-flex align-items-center">
                      {{ $tcust('Paiement.addCredits') }}
                    </div>
                    <div class="d-flex flex-row ms-auto px-3 my-2">
                      <MDBInput
                        id="Credits"
                        :label="$tcust('CheckingForm.checking.count')"
                        v-model="Credits"
                        type="number"
                        class="text-center tiny"
                      />
                      <div class="d-flex align-items-end ms-2">
                        {{ new Intl.NumberFormat($route.params.lang, {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(Credits * 10)
                        }}
                      </div>
                    </div>
                    <div class="ms-3 fst-italic text-muted">
                      {{ $tcust("Paiement.One.purchasesOptions") }}
                    </div>
                  </div>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBCard>

      <MDBCard
        v-if="$store.state.licenseChosen.License.Type === 1"
        class="gradient-group custom-box mx-auto my-3"
      >
        <MDBContainer
          fluid
          class="p-0"
        >
          <MDBRow>
            <!-- Title -->
            <MDBCol
              col="12"
              class="inside-block"
            >
              <div class="bg-box d-flex flex-row flex-wrap justify-content-between align-items-center px-3 py-2">
                <MDBBadge
                  tag="div"
                  class="chart-bg-group h-fit"
                >
                  <div class="fst-italic fw-normal w-fit fs-5">
                    eecheck Group
                  </div>
                </MDBBadge>
                <div class="mx-3 my-4" />
                <div class="mx-2">
                  <span class="fst-italic me-2">{{ $tcust("LicensesList.owner") }}</span>
                  <span class="fw-bold">{{ $store.state.licenseChosen.License.OwnerName }}</span>
                </div>

                <div
                  v-if="$store.state.licenseChosen.AvailableCreditsCount === 2147483647"
                  class="fst-italic text-muted w-100"
                >
                  <span v-html="$tcust('license.validityDate', { date: $store.state.licenseChosen.ValidityDate })" />
                </div>
              </div>
            </MDBCol>

            <!-- Infos -->
            <MDBCol
              col="12"
              class="inside-block p-2"
            >
              <div class="inside-box bg-box p-3">
                <div class="w-100">
                  <div class="d-flex flex-row flex-wrap justify-content-start align-items-center mx-auto w-100">
                    <div class="fw-bold w-fit">
                      {{ $tcust("LicensesList.group.subs", { n: $store.state.licenseChosen.SubLicenseCount }) }}
                    </div>
                    <div v-if="$store.state.licenseChosen.AvailableCreditsCount === 2147483647">
                      <span class="fst-italic text-muted">ToDo: Some purchase plans while License valid until {date}</span>
                    </div>

                    <div v-else>
                      <span class="fst-italic text-muted">ToDo: Some purchase plans</span>
                    </div>
                  </div>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBCard>
    </div>

    <div class="p-3 px-5 py-md-5 h-100">
      <div class="h-100 d-flex flex-column justify-content-start align-items-center">
        <MDBCard
          class="paypal gradient custom-box mx-auto my-3"
          :class="$store.state.app.theme === 'light' ? 'shadow-5' : 'shadow-5-strong'"
        >
          <MDBContainer
            class="p-0"
            fluid
          >
            <MDBRow>
              <MDBCol
                col="12"
                class="inside-block"
              >
                <div class="bg-box px-5 py-2 h-fit text-center fw-bold">
                  {{ $tcust('Paiement.total') }}
                  {{ new Intl.NumberFormat($route.params.lang, {
                      style: 'currency',
                      currency: 'EUR',
                    }).format(Credits * 10)
                  }}
                </div>
              </MDBCol>

              <MDBCol
                col="12"
                class="inside-block"
              >
                <div class="bg-box m-0 p-3">
                  <div id="paypalSdk" ref="paypal" />
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBCard>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  MDBBadge,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBInput
} from "mdb-vue-ui-kit";

import BigLicenseSwitcher from "@/components/ui/BigLicenseSwitcher.vue";

import { onMounted, ref } from 'vue';

const Credits = ref(null);

const paypal = ref(null);

const setPaypal = () => {
  const script = document.createElement('script');
  script.src = 'https://www.paypal.com/sdk/js?client-id=sb&currency=EUR';
  document.getElementById("paypalSdk").appendChild(script);
  script.addEventListener('load', () => setLoaded())
};

const setLoaded = () => {
  window.paypal.Buttons({
    createOrder: (data, actions) => {
      return actions.order.create({
        purchase_units: [
          {
            description: "this.prestation.courtedescription",
            amount: {
              currency_code: "EUR",
              value: 10
            }
          }
        ]
      });
    },
    // onApprove: async () => {
    //   this.paidFor = true;
    //   this.loading = false;
    // },
    onError: err => {
      console.log(err)
    }
  })
  .render(paypal.value)
};

onMounted(() => {
  setPaypal();
});
</script>

<style lang="scss">
@import "styles/var.scss";

#Credits {
  max-width: 120px;
}

.dark-theme {
  & #Paiement > div:last-child {
    box-shadow: -5px 5px 30px 0 rgb(0 0 0 / 21%);
  }
}
.light-theme {
  & #Paiement > div:last-child {
    box-shadow: -5px 5px 30px 0 rgb(0 0 0 / 11%);
  }
}
#Paiement {
  padding-top: 0px !important;
  min-height: 100vh;
  & .gradient {
    max-width: 600px;
  }
  & > div:last-child {
    max-width: 450px;
    width: 450px;
  }
  @media (max-width: 767.98px) {
    flex-wrap: wrap;
    & > div:last-child {
      width: 100%;
      max-width: 100%;
      border-left: none !important;
      box-shadow: none !important;
      padding: 0.5rem !important;
    }
  }
}
.paypal.gradient {
  min-width: 260px;
  // width: 330px;
  max-width: 90vw;
  margin: auto;
  margin-top: 5rem;
}
#app.dark-theme .paypal .bg-box {
  background-color: rgba($white, 0.85) !important;
  color: $dark !important;
}
</style>
